<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn dark v-on="on" color="grey darken-1" outlined raised
          >Return Serialized Item</v-btn
        >
      </template>
      <v-card>
        <ModelTitle title="Return Item" @close="close" />

        <v-card-text class="mt-5">
          <v-row class="mt-5" justify="space-between">
            <v-col>
              <v-text-field
                @focus="$event.target.select()"
                label="Serial Number"
                hide-details
                outlined
                prepend-inner-icon="search"
                clearable
                dense
                rounded
                @keyup.enter="loadImeiNumber()"
                v-model.trim="returnImeiNumber"
                ref="returnImeiNumber"
              ></v-text-field>
            </v-col>
            <v-col class="shrink">
              <v-btn color="success" @click="loadImeiNumber()">Search</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="lastInvoice">
          <div>
            <span class="font-weight-medium">Customer Name: </span>
            <span>{{ lastInvoice.Invoice.Customer.name }}</span>
          </div>
          <div>
            <span class="font-weight-medium">Invoice Date: </span>
            <span
              >{{ lastInvoice.Invoice.date | moment("MM/DD/YYYY") }} |
              {{ lastInvoice.Invoice.date | moment("from", "now") }}</span
            >
          </div>
          <div>
            <span class="font-weight-medium">Item Name: </span>
            <span>{{ lastInvoice.name }}</span>
          </div>
          <div>
            <span class="font-weight-medium">Amount: </span>
            <span>{{ lastInvoice.price | currency }}</span>
          </div>
          <div class="text-center" v-if="status">
            <v-chip
              class="ma-2"
              color="teal"
              text-color="white"
              @click:close="close"
            >
              <v-avatar left>
                <v-icon>mdi-checkbox-marked-circle</v-icon>
              </v-avatar>
              Available To Refund
            </v-chip>
          </div>

          <div class="text-center" v-else>
            <v-chip
              class="ma-2"
              color="red"
              text-color="white"
              @click:close="close"
            >
              <v-avatar left>
                <v-icon>mdi-close-circle</v-icon>
              </v-avatar>
              Item in Stock Can't Refund it
            </v-chip>
          </div>

          <v-divider class="mt-2"></v-divider>
          <v-row align="center">
            <v-col class="font-weight-medium">Return Value</v-col>
            <v-col>
              <v-text-field
                v-model="returnPrice"
                outlined
                ref="returnPriceRef"
                class="al-r"
                hide-details
                @focus="$event.target.select()"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="imeiReturnResult">
          <v-data-table
            dense
            :headers="headers"
            sort-by="date"
            :sort-desc="false"
            hide-default-footer
            :items="imeiReturnResult.Log"
          >
            <template v-slot:item="{ item }">
              <tr :style="{ 'background-color': color(item) }">
                <td
                  class="text-left pointer"
                  style="text-decoration: underline; color: blue"
                  @click="view(item)"
                >
                  {{ item.createdAt | moment("L") }}
                </td>

                <td>
                  <span>{{ item.message }}</span>
                </td>
                <td>
                  {{ item.type | typeFilter }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions v-if="imeiReturnResult">
          <v-spacer></v-spacer>
          <v-btn text color="primary" :disabled="!status" @click="submit()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import invoiceService from "@/modules/Invoice/service.js";
import purchaseService from "@/modules/Inventory/Purchase/service.js";

export default {
  data() {
    return {
      dialog: false,
      returnPrice: 0,
      returnImeiNumber: null,
      imeiReturnResult: null,
      lastInvoice: null,
      status: true,
      headers: [
        {
          text: "DATE",
          align: "left",
          value: "date",
          sortable: true,
        },

        {
          text: "MESSAGE",
          align: "center",
          value: "qty",
          sortable: true,
        },
        {
          text: "TYPE",
          align: "left",
          value: "name",
          sortable: true,
        },
      ],
    };
  },
  filters: {
    typeFilter(val) {
      let value = "";
      switch (val) {
        case "purchase":
          value = "Purchase";
          break;
        case "invoice":
          value = "Invoice";
          break;
        case "returnToVendor":
          value = "Return To Vendor";
          break;
        case "restock":
          value = "Restock";
          break;
        case "markItSold":
          value = "Mark It Sold";
          break;
        case "refund":
          value = "Refund";
          break;
        case "invoiceDeleted":
          value = "Serail Was Deleted";
          break;
        default:
          break;
      }
      return value;
    },
  },
  methods: {
    color(item) {
      let color = "white";

      if (item.type === "purchase") color = "#E1BEE7";
      if (item.type === "returnToVendor") color = "#BBDEFB";
      if (item.type === "invoice") color = "#A5D6A7";
      if (item.type === "return") color = "#FF7043";
      if (item.type === "refund") color = "#da0000";
      if (item.type === "restock") color = "#29B6F6";
      if (item.type === "markItSold") color = "#FFEE58";
      if (item.type === "invoiceDeleted") color = "#ff0080";
      return color;
    },
    submit() {
      this.lastInvoice.returnPrice = this.returnPrice;
      this.$emit("submit", this.lastInvoice);
      this.close();
    },
    close() {
      this.returnImeiNumber = null;
      this.imeiReturnResult = null;
      this.returnPrice = 0;
      this.dialog = false;
    },
    async loadImeiNumber() {
      const lastInvoice = await invoiceService.last({
        imeiNumber: this.returnImeiNumber,
      });
      this.lastInvoice = lastInvoice.data[0];
      // // console.log("detail", detail);
      const detail = await purchaseService.search({
        imeiNumber: this.returnImeiNumber,
        ignoreStatus: true,
        // 357270096924640
        // SH16DQGLEJMMT
      });

      // console.log("status", detail);
      // console.log("lastInvoice", lastInvoice);

      if (!detail.data) {
        this.$swal({
          title: "Return Item",
          text: "IMEI Number you entered is not found.",
          type: "error",
        });
      } else {
        this.imeiReturnResult = detail.data;
        if (this.imeiReturnResult) {
          this.status = this.imeiReturnResult.status === "sold" ? true : false;
        }

        this.returnPrice = this.lastInvoice.price;
      }
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.returnImeiNumber.focus();
        }, 100);
      }
    },
  },
};
</script>

<style scoped></style>
